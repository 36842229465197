.paginationWrapper {
  & > div {
    width: 100%;

    & > span {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
    }

    & > div {
      & > div {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        --tw-text-opacity: 1;
        color: rgb(55 65 81 / var(--tw-text-opacity));

        div[aria-label="submenu"] {
          bottom: 0px !important;
        }
      }

      & > nav {
        button {
          height: 36px;
          width: 36px;
          padding: 0px;
        }
      }
    }

    * {
      font-weight: 400 !important;
    }
  }
}