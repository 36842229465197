@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
}

*::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: transparent;
}

*::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #9CA3AF;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

[hidden] {
	display: none !important;
}